import React from 'react';
import { useLocation } from "react-router-dom";


import Vivaz from './features';
import './App.css';

const App = (props) => {
  const location = useLocation()

  window.dataLayer = window.dataLayer || [];
  console.log(window.dataLayer)
  console.log(location.pathname)
  if (location.pathname && location.state) {
    window.dataLayer.push({
      'event': 'pageview',
      'pagepath': location.pathname,
      'pageType': location.state
    })
  }

  return (
    <div className="App">
      <Vivaz />
    </div >
  );
};

export default App;
