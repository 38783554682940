import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  // brief
  briefContent: {
    height: '416px',
    backgroundColor: '#780050',

    '@media (min-width: 576px)': {
      // marginTop: '200px',
    },
  },
  contentTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '42px 0 22px 0',
    width: '100%',

    '@media (min-width: 576px)': {
      padding: '84px 0 36px 0',
    },
  },
  divContentTitle: {
    width: '100%',
    maxWidth: '576px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '35px',

    '@media (min-width: 576px)': {
      maxWidth: '1140px',
      padding: 0,
    },
  },
  spanTitle: {
    fontFamily: 'Graphie',
    fontSize: '24px',
    color: '#fff',
    letterSpacing: 0,
    lineHeight: '32px',
    fontWeight: 600,

    '@media (min-width: 576px)': {
      fontSize: '40px',
    },
  },
  contentThumb: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  divThumb: {
    width: '100%',
    height: '100%',
    maxWidth: '67px',
    maxHeight: '67px',
  },
  thumb: {
    width: '100%',
    height: 'auto',
  },
  quotes: {
    width: '28px',
    height: '16px',
    fill: '#960064',
  },
  spanBrief: {
    fontFamily: 'Graphie',
    fontSize: '12px',
    color: '#2B2823',
    letterSpacing: 0,
    lineHeight: '16px',
    display: 'table',
    textAlign: 'justify',
  },
  spanBriefName: {
    fontFamily: 'Graphie',
    fontSize: '14px',
    color: '#2B2823',
    letterSpacing: 0,
    lineHeight: '24px',
    fontWeight: 'bold',
    display: 'table',
    textAlign: 'justify',
  },
  divGraphic: {
    marginTop: '-35px',
    marginLeft: '40px',
    width: '400px',
    height: '120px',

    '@media (min-width: 576px)': {
      marginTop: '-65px',
      marginLeft: '45%',
      width: '440px',
    },
  },
  imgGraphic: {
    width: '100%',
    height: 'auto',
  },

  // footer
  footerContent: {
    height: '403px',
    color: '#fff',
    backgroundColor: '#960064',
    position: 'absolute',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    '@media (min-width: 576px)': {
      position: 'initial',
      alignItems: 'center',
    },
  },
  divFooterContent: {
    width: '100%',
    maxWidth: '1140px',
    height: '345px',
  },
  divFooterContent2: {
    width: '100%',
    maxWidth: '1140px',
    height: '56px',
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '100%',
    maxHeight: '347px',
    paddingLeft: '24px',

    '@media (min-width: 576px)': {
      flexDirection: 'row',
      width: '100%',
      padding: 0,
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  containerInfos: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '200px',
    marginBottom: '25px',
    width: '95%',

    '@media (min-width: 576px)': {
      marginBottom: 0,
      width: '100%',
      maxWidth: '720px',
    },
  },
  divInfo: {
    display: 'flex',
  },
  spanName: {
    fontFamily: 'Graphie',
    fontSize: '20px',
    color: '#fff',
    letterSpacing: 0,
    lineHeight: '24px',
    fontWeight: 600,
  },
  spanCreci: {
    fontFamily: 'DroidSans',
    fontSize: '12px',
    color: '#fff',
    letterSpacing: 0,
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
  divInfoEmail: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  divInfoEmailImg: {
    marginRight: '10px',
  },
  spanEmail: {
    fontFamily: 'DroidSans',
    fontSize: '12px',
    color: '#fff',
    letterSpacing: 0,
    lineHeight: '16px',
  },
  spanPhone: {
    fontFamily: 'Graphie',
    fontSize: '16px',
    color: '#fff',
    letterSpacing: 0,
    lineHeight: '24px',
    fontWeight: 600,
  },
  divDescription: {
    display: 'flex',
    width: '100%',
    // maxWidth: '270px',

    '@media (min-width: 576px)': {
      maxWidth: '700px',
    },
  },
  spanDescription: {
    fontFamily: 'DroidSans',
    fontSize: '12px',
    color: '#fff',
    letterSpacing: 0,
    lineHeight: '16px',
    display: 'table',
    textAlign: 'justify',
  },
  iconsSocialMedia: {
    display: 'block',
    marginRight: '5px',
  },
  containerMedias: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    maxWidth: '150px',

    '@media (min-width: 576px)': {
      height: '100%',
      maxHeight: '200px',
      alignItems: 'flex-start',
    },
  },
  footerLogo: {
    height: '100%',
    maxHeight: '56px',
    // borderTop: '1px solid #fff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '24px',

    '@media (min-width: 576px)': {
      paddingLeft: 0,
    },
  },
  contentBriefLarge: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  imgBack: {
    transform: 'rotate(270deg)',
    // marginRight: '35px',
  },
  divSwipe: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    // maxWidth: '900px',
  },
  imgFront: {
    transform: 'rotate(90deg)',
    // marginLeft: '30px',
  },
  hr: {
    width: '100vw',
    margin: 0,
    padding: 0,
  },
});
