import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    backgroundColor: '#f2f2f2',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '80px',
  },
  content: {
    display: 'flex',
    width: '100%',
    maxWidth: '1140px',
    justifyContent: 'flex-end',
  },
  contentDifferential: {
    width: '100%',
    maxWidth: '750px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  divLocalization: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    maxWidth: '272px',
    marginTop: '36px',
    marginBottom: '30px',

    '@media (min-width: 576px)': {
      maxWidth: '750px',
      marginTop: '76px',
    },
  },
  img: {
    width: '100%',
  },
  imgLocalization: {
    marginRight: '20px',
  },
  spanLocalization: {
    fontFamily: 'Graphie',
    fontSize: '24px',
    color: '#58545B',
    letterSpacing: 0,
    lineHeight: '40px',
    fontWeight: 700,
    display: 'table',
    textAlign: 'initial',
  },
  iconDifferential: {
    width: '40px',
    height: '40px',
  },
  divSpanDifferential: {
    width: '128px',
    height: '32px',

    '@media (min-width: 576px)': {
      width: '176px',
    },
  },
  spanDifferential: {
    fontFamily: 'Graphie',
    fontSize: '12px',
    color: '#2FDADA',
    letterSpacing: 0,
    lineHeight: '16px',
    fontWeight: 700,
    // display: 'table',
    // textAlign: 'center',
    textDecoration: 'underline',
  },
  divDifferential: {
    width: '100%',
    maxWidth: '272px',
    display: 'flex',
    justifyContent: 'flex-start',

    '@media (min-width: 576px)': {
      maxWidth: '375px',
      justifyContent: 'flex-start',
    },
  },
  divDifferentialAllIcons: {
    display: 'flex',
    flexDirection: 'column',

    '@media (min-width: 576px)': {
      flexDirection: 'row',
    },
  },
  boxIcons: {
    width: '100%',
    maxWidth: '272px',

    '@media (min-width: 576px)': {
      maxWidth: '750px',
    },
  },
});
