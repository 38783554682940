import { makeStyles } from '@material-ui/core';

import Profile from '../../../assets/images/img_perfil.png';
import smProfile from '../../../assets/images/sm-img-perfil.png';

export const useStyles = makeStyles({
  container: {
    // height: '100vh',
    // width: '100vw',
    // backgroundColor: '#fff',
  },
  // header
  phoneHolder: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '@media (min-width: 576px)': {
      flexDirection: 'row',
      flexWrap: 'wrap'
    },
    '@media (max-width: 576px)': {
      'alignItems': 'center',
    },
  },
  containerHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '56px',

    '@media (min-width: 576px)': {
      height: '80px',
    },

  },
  logoHeader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    '@media (min-width: 576px)': {
      width: '65%',
      justifyContent: 'flex-start',
    },
  },
  divLogo: {
    cursor: 'pointer',
    width: '100%',
    maxWidth: '100px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',

    '@media (min-width: 576px)': {
      marginLeft: '150px',
      maxWidth: '132px',
    },
  },
  logo: {
    width: '100%',
    height: 'auto',
  },
  divHeader: {
    backgroundColor: '#960064',
    width: '100%',
    height: '100%',

    '@media (min-width: 576px)': {
      width: '35%',
    },
  },
  // image background
  containerBanner: {
    position: 'relative',

    '@media (min-width: 576px)': {
      height: '100%',
      maxHeight: '344px',
    },
  },
  divBanner: {
    backgroundSize: 'cover',
    height: '100vh',
    maxHeight: '143px',
    minHeight: '100%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    mixBlendMode: 'screen',
    overflow: 'hidden',

    '@media (min-width: 576px)': {
      maxHeight: '344px',
    },
  },
  // profile
  containerProfile: {
    width: '100%',
    position: 'inherit',
    marginTop: '-50px',

    '@media (min-width: 576px)': {
      marginTop: '-300px',
    },
  },
  divProfile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '@media (min-width: 576px)': {
      flexDirection: 'row',
      alignItems: 'flex-end',
    },
  },
  profilePic: {},
  divPic: {
    width: '100%',
    maxWidth: '132px',
    display: 'flex',
    flexDirection: 'row',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',

    '@media (min-width: 576px)': {
      maxWidth: '368px',
    },
  },
  pic: {
    backgroundImage: `url(${smProfile})`,
    width: '132px',
    height: '132px',

    '@media (min-width: 576px)': {
      backgroundImage: `url(${Profile})`,
      width: '368px',
      height: '368px',
    },
  },
  profileInfos: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    paddingLeft: '24px',
    paddingRight: '24px',

    '@media (min-width: 576px)': {
      padding: '26px 40px',
      backgroundColor: '#fff',
      flexDirection: 'row',
      width: '100%',
      maxWidth: '768px',
      height: '100%',
      justifyContent: 'space-between',
    },
  },

  infosDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '24px',
    width: '46%',
    minWidth: '194px',

    '@media (min-width: 576px)': {
      alignItems: 'flex-start',
      marginTop: 0,
    },
    '@media (max-width: 576px)': {
      margin: '24px auto 0 auto',
    },
  },
  divName: {},
  spanName: {
    fontFamily: 'Graphie',
    fontSize: '24px',
    fontWeight: 700,
    color: '#960064',
    letterSpacing: 0,
    textAlign: 'center',
    lineHeight: '24px',
  },
  divCreci: {
    marginTop: '12px',
  },
  spanCreci: {
    fontFamily: 'DroidSans',
    fontSize: '12px',
    color: '#58545B',
    letterSpacing: 0,
    textAlign: 'center',
    lineHeight: '16px',
  },
  divEmail: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '12px',
  },
  divImgEmail: {
    width: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    height: '20px',
    marginRight: '10px',
  },
  email: {
    width: '100%',
    height: 'auto',
  },
  spanEmail: {
    fontFamily: 'Graphie',
    fontSize: '12px',
    color: '#58545B',
    letterSpacing: 0,
    lineHeight: '16px',
  },
  divPhone: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '11px',
    '@media (min-width: 576px)': {
      marginRight: '20px'
    }
  },
  divNumber: {
    display: 'flex'
  },
  divImgWhats: {
    width: '100%',
    maxWidth: '26px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    maxHeight: '26px',
    marginRight: '6px',
  },
  whats: {
    width: '100%',
    height: 'auto',
  },
  spanPhone: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '16px',
    color: '#58545B',
    letterSpacing: 0,
    lineHeight: '24px',
  },
  divLinkPhone: {
    marginTop: '-5px',
    cursor: 'pointer',

    '@media (min-width: 576px)': {
      marginLeft: '35px',
    },
  },
  spanLinkPhone: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px',
    color: '#960064',
    letterSpacing: 0,
    lineHeight: '16px',
  },
  hr: {
    margin: '20px 0',
    width: '100%',
    maxWidth: '280px',
    '@media (max-width: 576px)': {
      margin: '20px auto '
    },

  },
  infosSocialMedia: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',

    '@media (min-width: 576px)': {
      width: 'min-content',
      minHeight: '130px',
      justifyContent: 'space-between',
    },
  },
  socialMediaImages: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '24px',
    width: '100%',
    flexWrap: 'wrap',

    '@media (min-width: 576px)': {
      justifyContent: 'flex-end',
      marginBottom: 0,
      width: '160px'
    },
  },
  divSocialMediaImage: {
    width: '100%',
    maxWidth: '24px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    maxHeight: '24px',
    marginRight: '16px',
    marginBottom: '10px'
  },
  socialMediaButton: {
    cursor: 'pointer',
    width: '100%',
    minWidth: '232px',
    maxWidth: '280px',
    marginBottom: '27px',

    '@media (min-width: 576px)': {
      marginBottom: 0,
    },
  },
  button: {
    border: '1px solid #960064',
    backgroundColor: '#fff',
    fontFamily: 'Effra',
    color: '#960064',
    height: '39px',
    borderRadius: '20px',
    width: '100%',
    fontSize: '16px',
    letterSpacing: 0,
    textAlign: 'center',
    lineHeight: '24px',
    fontWeight: 400,
    cursor: 'pointer',
  },
});
