/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import FacebookConn from '../../../assets/images/bt-fb.png';
import graphicsSm from '../../../assets/images/graphics-sm.png';
import graphics from '../../../assets/images/graphics.png';
import Input from '../Input';
import { useStyles } from './styles';

const DialogBrief = (props) => {
  const { open, handleClose } = props;
  const classes = useStyles();

  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <div className={classes.briefContent}>
      <Dialog
        className={classes.dialogBrief}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md">
        <MuiDialogTitle disableTypography className={classes.root}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent className={classes.contentDialog}>
          <div className={classes.containertDialog}>
            <div className={classes.contentDialogDivs}>
              <div>
                <Typography gutterBottom className={classes.titleDialog}>
                  Deixe um depoimento
                </Typography>
              </div>
              <div className={classes.divSubtitleDialog}>
                <Typography gutterBottom className={classes.subtitleDialog}>
                  Utilize sua conta do Facebook para identificação
                </Typography>
              </div>
              <div>
                <div className={classes.divFaceConnection}>
                  <img
                    className={classes.faceConnection}
                    src={FacebookConn}
                    alt=""
                  />
                </div>
              </div>
              <div className={classes.divInput}>
                <Input placeholder="Qual seu nome?" />
              </div>
              <div className={classes.divTextArea}>
                <Input
                  placeholder="Digite seu depoimento"
                  multiline
                  rows={6.5}
                  textArea
                />
              </div>
              <div className={classes.divButton}>
                <button className={classes.buttonDialog} type="submit">
                  confirmar depoimento
                </button>
              </div>
            </div>
          </div>
          <div className={classes.ContentImg}>
            <div className={classes.divImg}>
              <img
                className={classes.img}
                src={width <= 575 ? graphicsSm : graphics}
                alt=""
              />
            </div>
          </div>
        </MuiDialogContent>
      </Dialog>
    </div>
  );
};

DialogBrief.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.any,
};

export default DialogBrief;
