import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import banner from '../../../assets/images/banner.png';
// import btAnalise from '../../../assets/images/btAnalise.svg';
import email from '../../../assets/images/e-mail.png';
import facebook from '../../../assets/images/facebook.png';
import instagram from '../../../assets/images/instagram.png';
import linkedin from '../../../assets/images/linkedin.png';
import logo from '../../../assets/images/logo.png';
import twitter from '../../../assets/images/twitter.png';
import DialogBrief from '../DialogBrief';
import HeaderPhones from '../HeaderPhones'
import { useStyles } from './styles';

const Header = ({ corretor }) => {

  const classes = useStyles();

  const [width, setWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const openWhatsApp = (telefone) => {
    let fone = telefone.replace(/[^\d]/g, '');
    window.open(
      `https://api.whatsapp.com/send/?phone=55${fone}&text&app_absent=0`
    );
  };

  return (
    <>
      <div className={classes.container}>
        {/* header */}
        <div className={classes.containerHeader}>
          <div className={classes.logoHeader}>
            <Link to={`/${corretor.url}`}>
              <div className={classes.divLogo}>
                <img className={classes.logo} src={logo} alt="logo" />
              </div>
            </Link>
          </div>
          <div className={classes.divHeader} />
        </div>
        {/* image background */}
        <div className={classes.containerBanner}>
          <div
            className={classes.divBanner}
            style={{
              backgroundImage: `url(${banner})`,
            }}
          />
          {/* profile */}
          <div className={classes.containerProfile}>
            <div className={classes.divProfile}>
              <div className={classes.profilePic}>
                <div className={classes.divPic}>
                  <img src={`${corretor.foto}`} className={classes.pic} />
                </div>
              </div>
              <div className={classes.profileInfos}>
                <div className={classes.infosDetails}>
                  <div className={classes.divName}>
                    <span className={classes.spanName}>
                      {corretor.nome}
                    </span>
                  </div>
                  <div className={classes.divCreci}>
                    <span className={classes.spanCreci}>
                      CRECI {corretor.creci}
                    </span>
                  </div>
                  <div className={classes.divEmail}>
                    <div className={classes.divImgEmail}>
                      <img className={classes.email} src={email} alt="" />
                    </div>
                    <span className={classes.spanEmail}>
                      {corretor.email}
                    </span>
                  </div>
                  {
                    typeof corretor.telefones !== 'undefined' && corretor.telefones.length &&
                    <div className={classes.phoneHolder}>
                      {corretor.telefones.map((item, index) => {
                        return (
                          <HeaderPhones
                            key={index}
                            classes={classes}
                            isWhatsapp={item.whatsApp}
                            telefone={item.telefone}
                          />
                        )
                      })}
                    </div>
                  }
                </div>
                {width < 575 && <hr className={classes.hr} />}
                <div className={classes.infosSocialMedia}>
                  <div className={classes.socialMediaImages}>

                    {typeof corretor.facebook !== 'undefined' && corretor.facebook !== null && corretor.facebook.trim().length > 0 &&
                      <>
                        <div className={classes.divSocialMediaImage}>
                          <a href={`https://facebook.com/${corretor.facebook}`} target="_blank" gtm-action="click" gtm-value="corretor:redes-sociais:facebook">
                            <img className={classes.email} src={facebook} alt="" />
                          </a>
                        </div>
                      </>
                    }

                    {typeof corretor.instagran !== 'undefined' && corretor.instagran !== null && corretor.instagran.trim().length > 0 &&
                      <>
                        <div className={classes.divSocialMediaImage}>
                          <a href={`https://instagram.com/${corretor.instagran}`} target="_blank" gtm-action="click" gtm-value="corretor:redes-sociais:instagram">
                            <img className={classes.email} src={instagram} alt="" />
                          </a>
                        </div>
                      </>
                    }

                    {typeof corretor.linkedin !== 'undefined' && corretor.linkedin !== null && corretor.linkedin.trim().length > 0 &&
                      <>
                        <div className={classes.divSocialMediaImage}>
                          <a href={`https://linkedin.com/${corretor.linkedin}`} target="_blank" gtm-action="click" gtm-value="corretor:redes-sociais:linkedin">
                            <img className={classes.email} src={linkedin} alt="" />
                          </a>
                        </div>
                      </>
                    }

                    {typeof corretor.twitter !== 'undefined' && corretor.twitter !== null && corretor.twitter.trim().length > 0 &&
                      <>
                        <div className={classes.divSocialMediaImage}>
                          <a href={`https://twitter.com/${corretor.twitter}`} target="_blank" gtm-action="click" gtm-value="corretor:redes-sociais:twitter">
                            <img className={classes.email} src={twitter} alt="" />
                          </a>
                        </div>
                      </>
                    }

                  </div>
                  {/* <div
                    aria-hidden="true"
                    onClick={openDialog}
                    className={classes.socialMediaButton}>
                    {/* <img src={btAnalise} alt="" /> */}
                  {/*<button className={classes.button} type="submit">
                      Deixar um depoimento
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogBrief open={open} handleClose={closeDialog} />
    </>
  );
};

export default Header;
