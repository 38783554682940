import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';


import icoGallerysvg from '../../../assets/images/ico-gallery.svg';
import shareWhite from '../../../assets/images/icon-share-white.svg';
import SubPropertyRecord from '../SubPropertyRecord/SubPropertyRecord';
import { useStyles } from './styles';
import './styles.css';

const PropertyRecord = ({ imovel }) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false)

  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const renderItems = imovel.galeria.map((pic) => {
    return (
      <div
        key={Math.random()}
        style={{
          width: '100%',
          maxWidth: '740px',
          height: '360px',
          padding: '0 4px',
        }}>
        <img
          style={{
            width: '100%',
            height: '320px',
            borderRadius: '5px',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
          }}
          src={`${pic.imgOriginal}?d=740x370`}
          alt=""
        />
        <div className={classes.iconShare}>
          <img src={shareWhite} alt="" />
        </div>
        <div className={classes.iconGallery} onClick={handleOpenModal}>
          <img src={icoGallerysvg} alt="" />
        </div>
      </div>
    );
  });

  return (
    <>
      <div className={classes.container}>
        <div
          style={{
            width: '100%',
            maxWidth: '740px',
            height: '370px',
            borderRadius: '5px',
            marginLeft: '5px',
          }}>
          <Carousel
            centerMode
            dynamicHeight={false}
            emulateTouch
            swipeScrollTolerance={50}
            showThumbs={false}
            showArrows={false}
            infiniteLoop
            showIndicators>
            {renderItems}
          </Carousel>
        </div>
      </div>

      <SubPropertyRecord galeria={imovel.galeriaLazer} classes={classes} open={openModal} handleCloseModal={(isOpen) => handleCloseModal(isOpen)} />
    </>
  );
};

export default PropertyRecord;
