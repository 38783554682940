import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  // briefContent: {},
  dialogBrief: {
    // border: '1px solid red',
    margin: '0 -20px',

    '@media (min-width: 576px)': {
      margin: 0,
    },
  },
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 0,

    '@media (min-width: 576px)': {
      margin: '16px 24px',
    },
  },
  closeButton: {},
  closeIcon: {
    color: '#960064',
  },
  contentDialog: {
    height: '100%',
    minHeight: '468px',
    maxHeight: '468px',
    // width: '100%',
    // minWidth: '100%',
    maxWidth: '650px',
    padding: 0,
    margin: '12px',

    '@media (min-width: 576px)': {
      minWidth: '650px',
      minHeight: '468px',
      margin: '',
      // border: '1px solid green',
    },
  },
  containertDialog: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    minHeight: '368px',
    // border: '1px solid blue',
  },
  contentDialogDivs: {
    width: '100%',
    maxWidth: '450px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // justifyContent: 'space-between',
    height: '100%',
    minHeight: '368px',
    // border: '1px solid red',
  },
  divFaceConnection: {
    width: '100%',
    maxWidth: '172px',
    marginBottom: '12px',
  },
  faceConnection: {
    width: '100%',
    height: 'auto',
    borderRadius: '5px',
  },
  buttonDialog: {
    width: '100%',
    maxWidth: '272px',
    height: '100%',
    minHeight: '48px',
    maxHeight: '48px',
    boxShadow: '0px 0px 37px -8px rgba(0, 0, 0, 0.19)',
    backgroundColor: '#960064',
    color: '#fff',
    border: 'none',
    textTransform: 'uppercase',
    borderRadius: '8px',
    fontFamily: 'Graphie',
    fontSize: '14px',
    textAlign: 'center',
    letterSpacing: '1.4px',
    lineHeight: '24px',
  },
  titleDialog: {
    fontFamily: 'Graphie',
    fontSize: '24px',
    fontWeight: 700,
    color: '#960064',
    letterSpacing: 0,
    textAlign: 'center',
    lineHeight: '36px',
    margin: ' 0 0 5px 0',
    padding: 0,

    '@media (min-width: 576px)': {
      fontSize: '30px',
      lineHeight: '28px',
      margin: ' 0 0 4px 0',
    },
  },
  divSubtitleDialog: {
    width: '100%',
    maxWidth: '270px',

    '@media (min-width: 576px)': {
      maxWidth: '450px',
    },
  },
  subtitleDialog: {
    fontFamily: 'Graphie',
    fontSize: '16px',
    fontWeight: 400,
    color: '#960064',
    letterSpacing: 0,
    textAlign: 'initial',
    lineHeight: '19px',
    margin: ' 0 0 8px 0',
    padding: 0,
  },
  divInput: {
    width: '100%',
    marginBottom: '16px',
  },
  divTextArea: {
    width: '100%',
    marginBottom: '8px',

    '@media (min-width: 576px)': {
      marginBottom: '24px',
    },
  },
  divButton: {
    width: '100%',
  },
  ContentImg: {
    // border: '1px solid red',
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: 0,
    top: '93%',

    '@media (min-width: 576px)': {
      top: '82%',
    },
  },
  divImg: {
    width: '220px',

    '@media (min-width: 576px)': {
      width: '260px',
    },
  },
  img: {
    width: '100%',
    height: 'auto',
  },
});
