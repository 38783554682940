import React from 'react';
import { Switch, Route } from 'react-router-dom';

import HomePage from '../features/pages/HomePage';
import RecordPage from '../features/pages/RecordPage';

const Router = () => {
  return (
    <Switch>
      <Route exact path="/:name/:imovel" component={RecordPage} />
      <Route path="/:name" component={HomePage} />
      <Route exact path="/" component={HomePage} />
    </Switch>
  );
};

export default Router;
