import React, { useState, useEffect } from 'react';

import localizacao from '../../../assets/images/icon-localizacao.svg';
import pattern from '../../../assets/images/pattern.png';
import { useStyles } from './styles';

const BannerProperty = ({ imovel }) => {
  const classes = useStyles();

  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  const goToDetailsPage = () => {
    if (window) {
      window.location.href = `https://www.meuvivaz.com.br/apartamento/${imovel.urlAmigavel}/localizacao`;
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.contentBanner}>
          <div style={{ marginBottom: '8px' }} className={classes.divSpanTitle}>
            <span className={classes.spanTitle}>
              {typeof imovel.tituloDescricao !== "undefined" && imovel.tituloDescricao !== null &&
                <div dangerouslySetInnerHTML={{ __html: imovel.tituloDescricao }} />
              }
            </span>
          </div>
          <div className={classes.divSpanTitle}>
            <span className={classes.spanSubtitle}>
              <div dangerouslySetInnerHTML={{ __html: imovel.descricao }} />
            </span>
          </div>
          <div className={classes.divLocalization}>
            <div className={classes.imgLocalization}>
              <img className={classes.img} src={localizacao} alt="" />
            </div>
            <div>
              <span className={classes.spanLocalization}>Localização</span>
            </div>
          </div>
          <div className={classes.divBanner}>
            <div className={classes.bannerPic} />
            <div className={classes.divSpanBanner}>
              <span className={classes.spanBanner}>
                Fale comigo agora e faça sua análise de crédito grátis!
              </span>
            </div>
          </div>
          <div
            style={{
              margin: '25px 0',
            }}
            className={classes.divSpanTitle}>
            <span className={classes.spanSubtitle}>
              {!!imovel.localizacao.detalhesLocalizacao.sobreOBairro && imovel.localizacao.detalhesLocalizacao.sobreOBairro.textoSecundario !== null &&
                <div dangerouslySetInnerHTML={{ __html: imovel.localizacao.detalhesLocalizacao.sobreOBairro.textoSecundario }} />
              }
            </span>
          </div>
          {/* <div>
            <button onClick={goToDetailsPage} className={classes.buttonInfoLocalization} type="button">
              detalhes da localização
            </button>
          </div> */}
        </div>
      </div>
      {width >= 1200 && (
        <div
          style={{
            position: 'absolute',
            top: '30%',
            left: '0',
          }}>
          <img src={pattern} alt="" />
        </div>
      )}
    </div>
  );
};

export default BannerProperty;
