import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  containerP: {
    backgroundColor: '#f2f2f2',
    paddingTop: 0,

    '@media (min-width: 576px)': {
      paddingTop: '100px',
    },
  },
  content: {
    padding: '35px 0 100px',
  },
  divTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0 24px',
    marginBottom: '25px',
    marginLeft: 0,

    '@media (min-width: 576px)': {
      padding: 0,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  divSpanTitle: {
    width: '100%',
    maxWidth: '1140px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  spanTitle: {
    fontFamily: 'Graphie',
    fontSize: '24px',
    fontWeight: 700,
    color: '#960064',
    letterSpacing: 0,
    textAlign: 'center',
    lineHeight: '32px',

    '@media (min-width: 576px)': {
      fontSize: '40px',
      lineHeight: '48px',
    },
  },
  divFormControl: {
    height: '100%',
    maxHeight: '46px',
    width: '100%',
    maxWidth: '230px',
    display: 'flex',
    alignItems: 'flex-end',
  },
  formControl: {
    width: '100%',
    padding: '0 15px',

    '@media (min-width: 576px)': {
      padding: '0 0 0 15px',
    },
  },
  inputLabel: {
    padding: '0 15px',
  },
  divSlide: {
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '270px',
  },
  slideTitle: {
    height: '32px',
    color: '#fff',
    fontFamily: 'Graphie',
    fontSize: '16px',
    fontWeight: 600,
    textAlign: 'center',
    letterSpacing: 0,
    lineHeight: '24px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px 5px 0 0',
    textTransform: 'uppercase',
  },
  carouelItem: {
    backgroundColor: '#fff',
    width: '100%',
    maxWidth: '270px',
    minWidth: '270px',
    height: '100%',
    minHeight: '629px',
    maxHeight: '630px',
    position: 'relative',
    borderRadius: '0 0 10px 10px',
  },
  carouelItemLarge: {
    // backgroundColor: '#fff',
    width: '100%',
    // maxWidth: '294px',
    // minWidth: '294px',
    height: '100%',
    minHeight: '570px',
    maxHeight: '570px',
    position: 'relative',
    '@media (max-width: 576px)': {
      maxHeight: '650px',
      minHeight: '650px',
    },
  },
  divImgslide: {
    backgroundSize: 'cover',
    height: '50%',
    maxHeight: '250px',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    mixBlendMode: 'normal',
    overflow: 'hidden',
    width: '100vw',
    maxWidth: '270px',
  },
  divImgslideLarge: {
    backgroundSize: 'cover',
    height: '50%',
    // maxHeight: '250px',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    mixBlendMode: 'normal',
    overflow: 'hidden',
    width: '100%',
    // maxWidth: '294px',
  },
  imgslide: {
    width: '100%',
    height: 'auto',
  },
  divInfoslide: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    backgroundColor: '#fff',
    // height: '60%',
    maxHeight: '256px',
    // borderRadius: '5px 10px 0 0',
    borderRadius: '5px 10px 10px 10px',
    display: 'flex',
    border: '1px solid #CCC',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '30px 20px',

    '@media (min-width: 576px)': {
      maxHeight: '350px',
      minHeight: '347px',
    },
    '@media (max-width: 576px)': {
      position: 'static',
      maxHeight: '296px',
      minHeight: '296px',
    },
  },
  divImoveName: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: '15px',

    '@media (min-width: 576px)': {
      height: '64px',
      paddingBottom: 0,
    },
  },
  spanImoveName: {
    fontFamily: 'Graphie',
    fontSize: '18px',
    fontWeight: 700,
    color: '#960064',
    letterSpacing: 0,
    display: 'table',
    textAlign: 'initial',
    lineHeight: '32px',
  },
  divAllIcons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingBottom: '15px',

    '@media (max-width: 576px)': {
      flexDirection: 'column',

    },
    '@media (min-width: 576px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingBottom: 0,
    },
  },
  divIconsInfos: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

    '@media (min-width: 576px)': {
      marginBottom: '10px',
    },
    '@media (max-width: 576px)': {
      width: '100%'
    },
  },
  divIcons: {
    width: '16px',
    height: '16px',
    marginRight: '5px',
  },
  icon: {
    width: '100%',
    height: '100%',
  },
  spanInfoIcons: {
    fontSize: '14px',
    fontFamily: 'Graphie',
    fontWeight: 400,
    color: '#58545B',
    letterSpacing: 0,
    lineHeight: '24px',
    textAlign: 'center',
  },
  divSinceFrom: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingBottom: '15px',

    '@media (min-width: 576px)': {
      paddingBottom: '15px',
    },
  },
  spanSinceFrom: {
    fontSize: '12px',
    fontFamily: 'Graphie',
    fontWeight: 400,
    color: '#58545B',
    letterSpacing: 0,
    lineHeight: '16px',
    textAlign: 'center',
  },
  spanSinceFromValue: {
    fontFamily: 'Graphie',
    fontSize: '22px',
    fontWeight: 700,
    color: '#000',
    letterSpacing: 0,
    textAlign: 'center',
    lineHeight: '32px',
  },
  buttonInfo: {
    cursor: 'pointer',
    width: '100%',
    maxWidth: '240px',
    height: '100%',
    minHeight: '56px',
    maxHeight: '56px',
    boxShadow: '0px 0px 37px -8px rgba(0, 0, 0, 0.19)',
    backgroundColor: '#2FDADA',
    color: '#fff',
    border: 'none',
    textTransform: 'uppercase',
    borderRadius: '5px',
    fontFamily: 'Graphie',
    fontSize: '14px',
    textAlign: 'center',
    letterSpacing: '1.4px',
    lineHeight: '24px',
  },
  buttonInfoShowAll: {
    width: '100%',
    maxWidth: '240px',
    height: '100%',
    minHeight: '56px',
    maxHeight: '56px',
    boxShadow: '0px 0px 37px -8px rgba(0, 0, 0, 0.19)',
    backgroundColor: '#FED300',
    color: '#000',
    border: 'none',
    textTransform: 'uppercase',
    borderRadius: '5px',
    fontFamily: 'Graphie',
    fontSize: '14px',
    textAlign: 'center',
    letterSpacing: '1.4px',
    lineHeight: '24px',
  },
  contentCategsFilters: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  divCategsFilters: {
    display: 'flex',
    width: '100%',
    maxWidth: '1140px',
    justifyContent: 'space-between',
  },
  contentCategs: {
    width: '100%',
    maxWidth: '745px',
    display: 'flex',
    flexDirection: 'row',
    height: '46px',
    marginBottom: '25px',
    borderBottom: '3px solid #99999936',
  },
  divContentCategs: {
    width: '100%',
    maxWidth: '500px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  spanCategs: {
    cursor: 'pointer',
    color: '#58545B',
    fontSize: '18px',
    textAlign: 'center',
    fontFamily: 'Graphie',
    lineHeight: '16px',
    letterSpacing: 0,
  },
  spanCategActive: {
    cursor: 'pointer',
    color: '#960064',
    fontSize: '18px',
    textAlign: 'center',
    fontFamily: 'Graphie',
    lineHeight: '16px',
    letterSpacing: 0,
    fontWeight: 600,
  },
  bullets: {
    marginTop: '15px',
  },
  divButton: {
    width: '100%',
    marginTop: '25px',

    '@media (min-width: 576px)': {
      marginTop: '80px',
    },
  },
});
