import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { BrowserRouter } from 'react-router-dom';
// import './assets/fonts/Graphie/Graphie-Bold.otf';
// import './assets/fonts/Graphie/Graphie-BoldItalic.otf';
// import './assets/fonts/Graphie/Graphie-Book.otf';
// import './assets/fonts/Graphie/Graphie-BookItalic.otf';
// import './assets/fonts/Graphie/Graphie-ExtraBold.otf';
// import './assets/fonts/Graphie/Graphie-ExtraBoldItalic.otf';
// import './assets/fonts/Graphie/Graphie-ExtraLight.otf';
// import './assets/fonts/Graphie/Graphie-ExtraLightItalic.otf';
// import './assets/fonts/Graphie/Graphie-Italic.otf';
// import './assets/fonts/Graphie/Graphie-Light.otf';
// import './assets/fonts/Graphie/Graphie-LightItalic.otf';
// import './assets/fonts/Graphie/Graphie-Regular.otf';
// import './assets/fonts/Graphie/Graphie-SemiBold.otf';
// import './assets/fonts/Graphie/Graphie-SemiBoldItalic.otf';
// import './assets/fonts/Graphie/Graphie-Thin.otf';
// import './assets/fonts/Graphie/Graphie-ThinItalic.otf';

import App from './App';

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);
