import iconWhats from '../../../assets/images/whats.png';
import { useStyles } from './styles'

const HeaderPhones = ({ isWhatsapp, telefone }) => {
  const classes = useStyles()

  const openWhatsApp = (telefone) => {
    let fone = telefone.replace(/[^\d]/g, '');
    window.open(
      `https://api.whatsapp.com/send/?phone=55${fone}&text&app_absent=0`
    );
  };

  return (<div className={`${classes.divPhone} ${isWhatsapp ? classes.noWhatsapp : ''
    } `}>
    <div className={classes.divNumber}>
      {isWhatsapp && <div className={classes.divImgWhats}>
        <img className={classes.whats} src={iconWhats} alt="whatsapp" />
      </div>}

      <span className={classes.spanPhone}>
        {telefone}
      </span>
    </div>

    {isWhatsapp && <div
      aria-hidden="true"
      onClick={() => openWhatsApp(telefone)}
      className={classes.divLinkPhone}>
      <span className={classes.spanLinkPhone}>
        Clique e fale agora
      </span>
    </div>}
  </div>)
}

export default HeaderPhones