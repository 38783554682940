import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  phoneHolder: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    color: '#F00',
    '@media (min-width: 576px)': {
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'initial',
    },
  },
  divPhone: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: '11px',
    width: '43%',
    minWidth: '136px',
    '@media (max-width: 576px)': {
      width: 'auto',
      minWidth: 'initial',
    },
  },

  divNumber: {
    display: 'flex',
  },
  divImgWhats: {
    width: '100%',
    maxWidth: '26px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    maxHeight: '26px',
    marginRight: '6px',
  },
  whats: {
    width: '100%',
    height: 'auto',
  },
  spanPhone: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '16px',
    color: '#58545B',
    letterSpacing: 0,
    lineHeight: '24px',
  },
  divLinkPhone: {
    cursor: 'pointer',
    marginTop: '-5px',

    '@media (min-width: 576px)': {
      marginLeft: '35px',
    },
  },
  spanLinkPhone: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px',
    color: '#960064',
    letterSpacing: 0,
    lineHeight: '16px',
  },
  noWhatsapp: {
  },
});
