import { makeStyles } from '@material-ui/core';

import Destaque from '../../../assets/images/img-destaque.png';
import Profile from '../../../assets/images/img_perfil.png';
import smDestaque from '../../../assets/images/sm-destaque.png';
import smProfile from '../../../assets/images/sm-img-perfil.png';

export const useStyles = makeStyles({
  container: {
    // height: '100vh',
    // width: '100vw',
    // backgroundColor: '#fff',
  },
  // header
  containerHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '56px',

    '@media (min-width: 576px)': {
      height: '80px',
    },
  },
  logoHeader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    '@media (min-width: 576px)': {
      width: '65%',
      justifyContent: 'flex-start',
    },
  },
  divLogo: {
    width: '100%',
    maxWidth: '100px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',

    '@media (min-width: 576px)': {
      marginLeft: '150px',
      maxWidth: '132px',
    },
  },
  logo: {
    width: '100%',
    height: 'auto',
  },
  divHeader: {
    backgroundColor: '#960064',
    width: '100%',
    height: '100%',

    '@media (min-width: 576px)': {
      width: '35%',
    },
  },
  // image background
  containerBanner: {
    position: 'relative',

    '@media (min-width: 576px)': {
      height: '100%',
    },
  },
  divBanner: {
    backgroundSize: 'cover',
    height: '100vh',
    maxHeight: '143px',
    minHeight: '100%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    mixBlendMode: 'screen',
    overflow: 'hidden',

    '@media (min-width: 576px)': {
      maxHeight: '344px',
    },
  },
  containerProfileProperty: {
    display: 'flex',
    flexDirection: 'column',
    position: 'inherit',

    '@media (min-width: 576px)': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      position: 'inherit',
      backgroundColor: '#f2f2f2'
    },
  },
  // profile
  containerProfile: {
    // width: '100%',
    position: 'inherit',
    marginTop: '-50px',

    '@media (min-width: 576px)': {
      marginTop: '-300px',
      marginRight: '8px',
    },
  },
  containeProperty: {
    // width: '100%',
    position: 'inherit',
    backgroundColor: '#f2f2f2',
    paddingTop: '27px',

    '@media (min-width: 576px)': {
      marginTop: '-300px',
      paddingTop: 0,
      backgroundColor: 'transparent',
      marginLeft: '8px',
    },
  },
  divProperty: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '@media (min-width: 576px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  propertyPic: {},
  divPropertyPic: {
    width: '100%',
    maxWidth: '320px',
    display: 'flex',
    flexDirection: 'row',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    position: 'relative',

    '@media (min-width: 576px)': {
      maxWidth: '751px',
    },
  },
  propertyPict: {
    backgroundImage: `url(${smDestaque})`,
    width: '320px',
    height: '329px',

    '@media (min-width: 576px)': {
      backgroundImage: `url(${Destaque})`,
      width: '751px',
      height: '368px',
    },
  },
  propertyIcons: {
    position: 'absolute',
    bottom: '23px',
    right: '17px',
  },
  divProfile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '@media (min-width: 576px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  favoriteIcon: {
    marginRight: '16px',
  },
  profilePic: {},
  divPic: {
    width: '100%',
    maxWidth: '132px',
    display: 'flex',
    flexDirection: 'row',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',

    '@media (min-width: 576px)': {
      maxWidth: '368px',
    },
  },
  pic: {
    backgroundImage: `url(${smProfile})`,
    width: '132px',
    height: '132px',

    '@media (min-width: 576px)': {
      backgroundImage: `url(${Profile})`,
      width: '368px',
      height: '368px',
    },
  },
  propertyTitle: {
    fontFamily: 'Graphie',
    fontSize: '40px',
    fontWeight: 700,
    color: '#363338',
    letterSpacing: 0,
    lineHeight: '48px',
    display: 'table',
    textAlign: 'initial',

    '@media (min-width: 576px)': {
      fontSize: '48px',
      lineHeight: '56px',
    },
  },
  propertySubtitle: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
    color: '#58545B',
    letterSpacing: 0,
    lineHeight: '24px',
    display: 'table',
    textAlign: 'initial',
  },
  plusIcon: {
    width: '100%',
    height: 'auto',
    marginRight: '4px',
  },
  divPropertyInfos: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    paddingBottom: '18px'
  },
  propertyInfosContent: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '650px',
  },
  propertyInfosDetails: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '272px',
    paddingTop: '10px',

    '@media (min-width: 576px)': {
      maxWidth: '520px',
      paddingTop: '26px',
    },
  },
  propertyIconsLarge: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '10px',
  },
  divSubtitle: {
    marginBottom: '12px',
    width: '245px',

    '@media (min-width: 576px)': {
      width: '470px',
    },
  },
  propertyDetailsPlus: {
    display: 'flex',
    flexDirection: 'row',
  },
  divIconsLarge: {
    display: 'flex',
    flexDirection: 'column',
  },
  divIconsLargeF: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '24px',
  },
  spanIcons: {
    fontFamily: 'Graphie',
    fontSize: '12px',
    fontWeight: 700,
    color: '#960064',
    letterSpacing: 0,
    lineHeight: '16px',
    display: 'table',
    textAlign: 'center',
  },
  divPlusIcon: {
    height: '16px',
    width: '16px',
    marginTop: '2px',
    marginRight: '4px',
  },
  DetailsPlusF: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '10px',
  },
  DetailsPlus: {
    display: 'flex',
    flexDirection: 'row',
  },
  spanPlus: {
    fontFamily: 'Graphie',
    fontSize: '14px',
    fontWeight: 700,
    color: '#363338',
    letterSpacing: 0,
    lineHeight: '24px',
    display: 'table',
    textAlign: 'initial',
  },
  profileInfos: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    paddingLeft: '24px',
    paddingRight: '24px',

    '@media (min-width: 576px)': {
      backgroundColor: '#fff',
      flexDirection: 'column',
      width: '100%',
      maxWidth: '368px',
      height: '100%',
      justifyContent: 'space-between',
    },
  },
  infosDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '24px',

    '@media (min-width: 576px)': {
      alignItems: 'flex-start',
      width: '100%',
      minWidth: '318px',
      marginTop: '24px',
    },
  },
  divName: {},
  spanName: {
    fontFamily: 'Graphie',
    fontSize: '24px',
    fontWeight: 700,
    color: '#960064',
    letterSpacing: 0,
    textAlign: 'center',
    lineHeight: '24px',
  },
  divCreci: {
    marginTop: '12px',
  },
  spanCreci: {
    fontFamily: 'DroidSans',
    fontSize: '12px',
    color: '#58545B',
    letterSpacing: 0,
    textAlign: 'center',
    lineHeight: '16px',
  },
  divEmail: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '12px',
  },
  divImgEmail: {
    width: '100%',
    maxWidth: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    maxHeight: '20px',
    marginRight: '10px',
  },
  email: {
    width: '100%',
    height: 'auto',
  },
  spanEmail: {
    fontFamily: 'Graphie',
    fontSize: '12px',
    color: '#58545B',
    letterSpacing: 0,
    lineHeight: '16px',
  },
  phoneHolder: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    '@media (min-width: 576px)': {
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'initial',
    }
  },
  hr: {
    margin: '20px 0',
    width: '100%',
    maxWidth: '280px',
    '@media (max-width: 576px)': {
      margin: '20px auto',
    },

    '@media (min-width: 576px)': {
      maxWidth: '318px',
    },
  },
  infosSocialMedia: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',

    '@media (min-width: 576px)': {
      width: 'min-content',
      // minHeight: '130px',
      justifyContent: 'space-between',
    },
  },
  socialMediaImages: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '24px',
    width: '100%',
    flexWrap: 'wrap',

    '@media (min-width: 576px)': {
      justifyContent: 'flex-start',
      marginBottom: 0,
      minWidth: '318px',
    },
  },
  divSocialMediaImage: {
    width: '100%',
    maxWidth: '24px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    maxHeight: '24px',
    marginRight: '16px',
    marginBottom: '10px'
  },
  socialMediaButton: {
    cursor: 'pointer',
    width: '100%',
    minWidth: '232px',
    maxWidth: '280px',
    marginBottom: '27px',

    '@media (min-width: 576px)': {
      maxWidth: '318px',
      marginBottom: '34px',
    },
  },
  button: {
    cursor: 'pointer',
    border: '1px solid #960064',
    backgroundColor: '#fff',
    fontFamily: 'Effra',
    color: '#960064',
    height: '39px',
    borderRadius: '20px',
    width: '100%',
    fontSize: '16px',
    letterSpacing: 0,
    textAlign: 'center',
    lineHeight: '24px',
    fontWeight: 400,
  },
});
