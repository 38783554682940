import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';

import entrada from '../../../assets/images/icon-entrada.svg';
import fgts from '../../../assets/images/icon-fgts.svg';
import renda from '../../../assets/images/icon-renda.svg';
import subsidio from '../../../assets/images/icon-subsidio.svg';
import valor from '../../../assets/images/icon-valor.svg';
import { useStyles } from './styles';

const Featured = (props) => {

  const formatCash = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return `${+(n / 1e3).toFixed(1)} Mil`;
    if (n >= 1e6 && n < 1e9) return `${+(n / 1e6).toFixed(1)} Milhão`;
    if (n >= 1e9 && n < 1e12) return `${+(n / 1e9).toFixed(1)} Bilhão`;
    if (n >= 1e12) return `${+(n / 1e12).toFixed(1)}T`;
    return n;
  };

  const { width, imovel } = props;
  const classes = useStyles();
  return (
    <div className={classes.containerFeatured}>
      <div className={classes.container}>
        <div className={classes.content}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <div className={classes.contentFeatured}>
                <div className={classes.contentDiv}>
                  <div className={classes.divLeft}>
                    <img className={classes.img} src={valor} alt="" />
                  </div>
                  <div className={classes.divLeftContent}>
                    <p className={classes.pDesc}>A partir de</p>
                    <p className={classes.pValue}>R$ {formatCash(imovel.precoMinimo)}</p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.contentFeatured}>
                <div
                  className={
                    width > 575 ? classes.contentDiv : classes.contentDivRight
                  }>
                  <div
                    className={
                      width > 575 ? classes.divLeft : classes.divRigth
                    }>
                    <img className={classes.img} src={subsidio} alt="" />
                  </div>
                  <div
                    className={
                      width > 575
                        ? classes.divLeftContent
                        : classes.divRightContent
                    }>
                    <p className={classes.pDesc}>Subsídio de até</p>
                    <p className={classes.pValue}>R$ {formatCash(imovel.subsidio)}</p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.contentFeatured}>
                <div className={classes.contentDiv}>
                  <div className={classes.divLeft}>
                    <img className={classes.img} src={renda} alt="" />
                  </div>
                  <div className={classes.divLeftContent}>
                    <p className={classes.pDesc}>Renda a partir de</p>
                    <p className={classes.pValue}>R$ {formatCash(imovel.rendaMinima)}</p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.contentFeatured}>
                <div
                  className={
                    width > 575 ? classes.contentDiv : classes.contentDivRight
                  }>
                  <div
                    className={
                      width > 575 ? classes.divLeft : classes.divRigth
                    }>
                    <img className={classes.img} src={entrada} alt="" />
                  </div>
                  <div
                    className={
                      width > 575
                        ? classes.divLeftContent
                        : classes.divRightContent
                    }>
                    <p className={classes.pDesc}>Entrada em até</p>
                    <p className={classes.pValue}>{imovel.entradaEmAte}x</p>
                  </div>
                </div>
              </div>
            </Grid>
            {imovel.mostrarIconeFGTS === true &&
              <Grid item xs={12} md={4}>
                <div className={classes.contentFeatured}>
                  <div className={classes.contentDiv}>
                    <div className={classes.divLeft}>
                      <img className={classes.img} src={fgts} alt="" />
                    </div>
                    <div className={classes.divLeftContent}>
                      <p className={classes.pDesc}>Use o seu</p>
                      <p className={classes.pValue}>FGTS</p>
                    </div>
                  </div>
                </div>
              </Grid>
            }
            {imovel.mostrarIconeItbi === true &&
              <Grid item xs={12} md={4}>
                <div className={classes.contentFeatured}>
                  <div
                    className={
                      width > 575 ? classes.contentDiv : classes.contentDivRight
                    }>
                    <div
                      className={
                        width > 575 ? classes.divLeft : classes.divRigth
                      }>
                      <img className={classes.img} src={subsidio} alt="" />
                    </div>
                    <div
                      className={
                        width > 575
                          ? classes.divLeftContentItbi
                          : classes.divRightContentItbi
                      }>
                      <p className={classes.pDescItbi}>ITBI e Registro Grátis</p>
                    </div>
                  </div>
                </div>
              </Grid>
            }
          </Grid>
        </div>
      </div>
    </div>
  );
};

Featured.propTypes = {
  width: PropTypes.isRequired,
};

export default Featured;
